import React from "react";
import Header from "./Header";
import Footer from "../conlcusion/Footer";
import projects from "../../data/projetcs.json";
import Scroller from "../Scroller";

export default function ProjectProcess() {
	const process = projects[0].process;
	return (
		<>
			<Scroller />
			<Header pageTitle={projects[0].title} projectNavigation={true} />
			<div className="container project-informations">
				<div className="row">
					<div className="col-12 screen p-4">
						<p>{process.introduction}</p>
					</div>
				</div>
				{process.steps.map((step) => (
					<div className="row" key={step.stepId}>
						<div className="col-12 screen p-4">
							<h4>
								{step.title}
								{" : "}
								{step.duration}
							</h4>
							<hr />
							<p>{step.description}</p>
						</div>
					</div>
				))}
			</div>
			<Footer />
		</>
	);
}
