import React from "react";
import avatar from "../../assets/avatar.jpg";
import "../../styles/header.css";
import ProjectNavigation from "./ProjectNavigation";

export default function Header({ pageTitle, projectNavigation=false }) {
	return (
		<div className="container-fluid sticky-top" id="header">
			<div className="row align-items-center" >
				<div className="col-4">
					<a href="/" id="back-icon">
						<i className="bi bi-arrow-left-short"></i>
					</a>
				</div>
				<div className="col-4 text-center">
					<h6 style={{color:"var(--cinnabar)"}}>{pageTitle}</h6>
				</div>
				<div onClick={()=>{document.getElementById('footer').scrollIntoView({behavior:"smooth"});}} className="col-4 text-end">
					<img id="head-avatar" style={{ cursor:"pointer", borderRadius: "50%", right: 0, border:"solid 4px var(--cinnabar)" }} src={avatar} alt="avatar" />
				</div>
			</div>
			{projectNavigation&&<ProjectNavigation />}
		</div>
	);
}
