import React, { useState } from "react";
import Header from "./Header";
import "../../styles/singleWork.css";
import Footer from "../conlcusion/Footer";
import projects from "../../data/projetcs.json";
import Gallery from "../Gallery";
import Scroller from "../Scroller";

export default function SingleWork() {
	const overview = projects[0].overview;
	const [selectedImage,setSelectedImage] = useState(null);
	const openLink = <a target="_blank" rel="noreferrer" href={overview.projectLink}>Visit website <i className="bi bi-box-arrow-up-right"></i></a>
	return (
		<>
			<Scroller />
			<Header pageTitle={projects[0].title} projectNavigation={true} />
			<div className="container" id="work-samples-container">
				<div className="row p-2">
					<div className="col-12 screen p-5 py-4">
						<p>{overview.description}</p>
						{openLink}
					</div>
				</div>
				<div className="row p-2 ">
					<Gallery selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
					{overview.screens.map((screen) => (
						<div key={screen.screenId} className="col-12 my-1 p-5 screen">
							<div className="row">
								<div className="project-highlights-desc col-12">
									<h4 className="d-md-none">{screen.title}</h4>
								</div>
								<hr className="d-md-none"/>
								<div className="col-md-6">
									<img onClick={()=>{setSelectedImage(screen.screenshots[0])}} src={screen.screenshots[0]} className="img-fluid project-highlights" alt="Home" />
								</div>
								<hr className="d-md-none mt-3"/>
								<div className="col-md-6 project-highlights-desc">
									<h4 className="d-none d-md-block">{screen.title}</h4>
									<hr className="d-none d-md-block"/>
									<p>{screen.description}</p>
									<hr className="d-none d-md-block"/>
									{openLink}
								</div>
							</div>
						</div>
					))}
				</div>	
				<div className="row p-2">
					<div className="col-12 p-5">

					<p>
						How does it look on mobile devices?
					</p>
					</div>

					{overview.mobileView.map((view) => (
						<div key={view.viewId} className="col-12 col-md-4 my-1 p-5 screen">
								<div className="project-highlights-desc col-12">
									<h4 className="d-md-none">{view.viewLabel}</h4>
								</div>
								<hr className="d-md-none"/>
								<div>
									<img onClick={()=>{setSelectedImage(view.viewImage)}} src={view.viewImage } className="img-fluid project-highlights" alt="Home" />
								</div>
								<hr className="d-md-none mt-3"/>
								<div className="project-highlights-desc">
									{openLink}
								</div>
							</div>
					))}
				</div>

			</div>
			<Footer />
		</>
	);
}
