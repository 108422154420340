import React, { useEffect } from "react";
import "../../styles/projectNavigation.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function ProjectNavigation() {
	const url = useLocation();
	const navigate = useNavigate();
	function goTo(link){
		navigate(link);
	}
	useEffect(() => {
		// Set the body background when this component is mounted
		document.body.style.backgroundColor = "black";

		// Cleanup: Reset the background when this component is unmounted
		return () => {
			document.body.style.backgroundColor = "";
		};
	}, []);
	return (

			<div className="row " id="project-navigation">
				<div onClick={()=>goTo("/gkwiz/overview")} className={"col-4 text-center project-navigation-tab"+(url.pathname.includes("overview")?" active":"")}>
					<Link to="/gkwiz/overview">Overview</Link>
				</div> 
				<div onClick={()=>goTo("/gkwiz/process")} className={"col-4 text-center project-navigation-tab"+(url.pathname.includes("process")?" active":"")}>
					<Link to="/gkwiz/process">Process</Link>
				</div>
				<div onClick={()=>goTo("/gkwiz/insights")} className={"col-4 text-center project-navigation-tab"+(url.pathname.includes("insights")?" active":"")}>
					<Link to="/gkwiz/insights">Insights</Link>
				</div>
			</div>
	);
}
