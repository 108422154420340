import React from 'react'
import Home from './Introduction/Home'
import Works from './Devlopment/Works'
import Footer from './conlcusion/Footer'

export default function Welcome() {
  return (
    <>
        <Home />
        <Works />
        <Footer />
    </>
  )
}
