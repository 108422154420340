/* eslint-disable no-unused-vars */
import ProjectInsights from "./components/Devlopment/ProjectInsights";
import ProjectProcess from "./components/Devlopment/ProjectProcess";
import SingleWork from "./components/Devlopment/SingleWork";
import Works from "./components/Devlopment/Works";
import Home from "./components/Introduction/Home";
import Footer from "./components/conlcusion/Footer";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Welcome from "./components/Welcome";

function App() {
	return <>
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/:project/overview" element={<SingleWork />} />
      <Route path="/:project/process" element={<ProjectProcess />} />
      <Route path="/:project/insights" element={<ProjectInsights />} />
    </Routes>
  </>;
}

export default App;
