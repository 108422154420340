import React from "react";
import avatar from "../../assets/avatar.jpg";
import "../../styles/home.css";

export default function Home() {
	return (
		<div id="home" className="container-fluid">
			<div className="row p-4">
				<div className="col-12 p-5 pt-1 col-md-3">
					<img className="img-fluid" id="avatar" src={avatar} alt="avatar" style={{ borderRadius: "50%" }} />
				</div>
				<div id="welcome-text-section" className="col-12 col-md-6">
					<h1 id="welcome-animation">Welcome !</h1>
					<p id="context">My name is Jerry Cenatus. I'm a website developer and social media graphic designer. This is my portfolio.</p>
					<p>I create portfolios, corporate websites, landing pages, single page web applications and social media graphic content.</p>
					<p id="purpose">Here you will find the projects I've been working on, information about them, my philosophy, a list of my skills, and finally, <a href="#about" onClick={()=>{document.getElementById('footer').scrollIntoView({behavior:"smooth"});}}>how you can join me.</a></p>
					<div className="py-4 text-end">
						<a className="custom-btn-dark" href="#projects">
							<i className="bi bi-tv-fill"></i> Projects
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
