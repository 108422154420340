import React, { useState } from "react";
import Gallery from "../Gallery";

export default function ProjectBody({ image, open = false,title,description }) {
	const [selectedImage,setSelectedImage] = useState(null);
	return (
		<div className="container-fluid single-project my-4 p-4">
			{!open && (<>
				<div className="row p-2 text-center">
					<div className="col-12">
						<h6>{title}</h6>
					</div>
				</div>
				<hr/>
			</>
			)}
			<div className="row">
				<div className="col-12">
					<Gallery setSelectedImage={setSelectedImage} selectedImage={selectedImage} />
					<img onClick={()=>setSelectedImage(image)} src={image} className="img-fluid" alt="project1" />
				</div>
				<div className="col-12 py-3">
					<p>{description}</p>
					<p>
					Click the button below for more information about this project and the process behind it.
					</p>
				</div>
				{!open && (
					<div className="col-12">
						<p className="text-center">
							<a className="custom-btn-dark" href="/gkwiz/overview">Open <i className="bi bi-box-arrow-in-up-right"></i></a>
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
