// src/Gallery.js
import React from "react";
import "../styles/gallery.css";

const Gallery = ({ selectedImage = null, setSelectedImage }) => {

	const closeModal = () => {
		setSelectedImage(null);
	};

	return (
		selectedImage && (
			<div className="gallery-modal" onClick={closeModal}>
				<span className="close-modal">&times;</span>
				<img src={selectedImage} alt="Selected" className="modal-image" />
			</div>
		)
	);
};
export default Gallery;
