import React from "react";
import ProjectBody from "./ProjectBody";
import "../../styles/projects.css";
import projects from "../../data/projetcs.json";

export default function Works() {
	return (
		<div className="container-fluid" id="projects">
			{projects.map((project)=>(
				<ProjectBody key={project.projectId} image={project.cover} title={project.title} description={project.overview.description} />
			))}
		</div>
	);
}
