import React from "react";
import "../../styles/footer.css";

export default function Footer() {
	return (
		<div className="container-fluid" id="footer">
			<div className="row">
				<div id="about-header" className="col-12 text-center" onClick={()=>{document.getElementById('footer').scrollIntoView({behavior:"smooth"});}}>
					<h5>Let's build something together</h5>
				</div>
			</div>
			<hr />
			<div className="row footer-section">

				<div className="col-12 col-md-4">
					<h6>Philosophy <i className="bi bi-infinity"></i></h6>
					<p>
						My philosophy is: 'Nearly everything you can imagine can be created; it only takes time, effort, attention, and intention.'
					</p>
					<p>
					I know this may seem a bit ambitious or arrogant. However, if you are realistic about your strengths and weaknesses, pay attention to your thoughts, take the time to conceptualize your ideas, put effort into the work, and act intentionally, You will be able to bring to life everything you truly desire.
					</p>
					<hr/>
					<h6>Objectifs <i className="bi bi-bullseye"></i></h6>
					<ul className="simple-list">
						<li>Design innovative, outstanding, and distinctive web applications.</li>
						<li>Create easeful, engaging, and meaningful user-experiences.</li>
						<li>Develop impactful and efficient website features</li>
					</ul>
				</div>
				
				<div className="col-12 col-md-4">
					<h6>Web Skills <i className="bi bi-cpu-fill"></i></h6>
					<ul className="simple-list">
						<li>I can design and animate user interfaces using CSS.</li>
						<li>I know how to structure accessible web pages with HTML.</li>
						<li>I use PHP to create website backends.</li>
						<li>Creating interactive web pages with JavaScript is something I truly enjoy.</li>
						<li>I’ve fallen in love with React.js.</li>
						<li>MySQL is my favorite server tool for storing data.</li>
						<li>JSON is my lifesaver when it comes to transporting and sharing data between applications.</li>
						<li>Last but not least, I know how to use FileZilla and hosting services to deploy and manage web applications, moving them from development to production.</li>
					</ul>
				</div>
				<div className="col-12 col-md-4">
					<h6>Contact <i class="bi bi-person-lines-fill"></i></h6>
					<ul className="custom-list">
						<li>
							<a target="_blank" rel="noreferrer" href="https://wa.me/+19544964767">Direct message via Whatsapp <i className="bi bi-whatsapp"></i></a>
						</li>
						<li>
							<a target="_blank" rel="noreferrer" href="https://x.com/thejerrycenatus?t=6ISCMXSJYeiV7enO0R3oSw&s=09">Follow me on Twitter <i className="bi bi-twitter-x"></i></a>
						</li>
						<li>
							<a target="_blank" rel="noreferrer" href="https://www.upwork.com/freelancers/~018eb8bb9cc55d8e2e">Let's work together on Upwork <i className="bi bi-person-badge-fill"></i></a>
						</li>
					</ul>
				</div>
			</div>
			<hr />
			<div className="row ">
				<div className="col-12 text-center">
					<small>
						<strong>© Jerry Cenatus 2024</strong>
					</small>
				</div>
			</div>
		</div>
	);
}
