import React from "react";
import Header from "./Header";
import Footer from "../conlcusion/Footer";
import projects from "../../data/projetcs.json";
import Scroller from "../Scroller";

export default function ProjectInsights() {
	const insights = projects[0].insights;
	return (
		<>
			<Scroller />
			<Header pageTitle={projects[0].title} projectNavigation={true} />
			<div className="container project-informations">
				{insights.map((insight)=>(

					<div className="row screen p-4" key={insight.insightId}>
					<div className="col-12">
						<h4>{insight.title}</h4>
						<hr/>
						<p>
							{insight.about}
						</p>
					</div>
				</div>
				))}
			</div>
			<Footer />
		</>
	);
}
